import React, {useState} from "react";
import {cc, Input, Paragraph, Subheading} from "./Typography";
import {buttonStyle} from "../utils/styles";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {Center} from "./Center";
import {GraphQlError} from "./GraphQLError";
import {Timestamp} from "./Timestamp";
import Markdown from "react-markdown";
import {allRecommendationFields, allTeamInsightFields} from "./TeamSummary";
import {PoweredByAI} from "./PoweredByAI";
import {Error, Loading} from "./Indicators";
import {RichText} from "./RichText";

const allGenerationMetadataFields = gql`
    fragment AllGenerationMetadataFields on GenerationMetadata {
        id
        model
        generationCount
        errors
        promptTokenCount
        generationTokenCount
    }
`

const GET_TEAM_INSIGHT_LIST = gql`
    ${allTeamInsightFields}
    query getTeamInsightsForUser($userId: String!) {
        teamInsightListForUser(user: $userId) {
            ...AllTeamInsightFields
        }
    }
`;

const RECORD_TEAM_INSIGHT = gql`
    ${allTeamInsightFields}
    mutation recordTeamInsight($userId: String!, $summary: String!, $generationId: String) {
        recordTeamInsight(id: $userId, summary: $summary, generationId: $generationId) {
            ...AllTeamInsightFields
        }
    }
`;

const GENERATE_TEAM_INSIGHT = gql`
    ${allTeamInsightFields}
    ${allGenerationMetadataFields}
    query generateTeamInsightForUser($userId: String!) {
        generateTeamInsightForUser(id: $userId) {
            insight {
                ...AllTeamInsightFields
            }
            metadata {
                ...AllGenerationMetadataFields
            }
        }
    }
`;

const GENERATE_RECOMMENDATIONS = gql`
    ${allRecommendationFields}
    ${allGenerationMetadataFields}
    query generateRecommendationsForUser($userId: String!) {
        generateRecommendationsForUser(id: $userId) {
            recommendations {
                ...AllRecommendationFields
            }
            metadata {
                ...AllGenerationMetadataFields
            }
        }
    }
`;

const RECORD_RECOMMENDATION = gql`
    ${allRecommendationFields}
    mutation recordRecommendation($userId: String!, $name: String!, $summary: String!, $generationId: String) {
        recordRecommendation(id: $userId, name: $name, summary: $summary, generationId: $generationId) {
            ...AllRecommendationFields
        }
    }
`;

export function CoachConsole({user, className}) {
    const {loading, error, data} = useQuery(GET_TEAM_INSIGHT_LIST, {variables: {userId: user}});

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error}/>;

    return <div className={className}>
        <Subheading>Your insights</Subheading>
        <TeamInsights insights={data.teamInsightListForUser}/>
        <RecordTeamInsights user={user}/>

        <Subheading>Record your recommendations</Subheading>
        <RecordRecommendations user={user}/>
    </div>;
}

function TeamInsights({insights}) {
    const [selected, setSelected] = useState(insights && insights.length > 0 ? insights[0].createdAt : null);

    return <>
        <select id="selected-insight" value={selected} onChange={e => setSelected(e.target.value)} className="w-full">
            {insights && insights.map(i => <option key={i.createdAt} value={i.createdAt}><Timestamp ts={i.createdAt}/>
            </option>)}
        </select>
        <Insight insight={insights.find(i => i.createdAt === selected)}/>
    </>;

    function Insight({insight}) {
        return insight && <>
            <Subheading>By {insight.source}:</Subheading>
            <Markdown components={{
                p(props) {
                    const {node, ...rest} = props;
                    return <Paragraph {...rest} />;
                }
            }}>{insight.summary}</Markdown>
        </>
    }
}

function RecordTeamInsights({user}) {
    const [generateInsight] = useLazyQuery(GENERATE_TEAM_INSIGHT);
    const [recordInsight, {loading, error}] = useMutation(RECORD_TEAM_INSIGHT);
    const [summary, setSummary] = useState('');
    const [metadata, setMetadata] = useState(null);

    if (loading) return 'Submitting';
    if (error) return `Submission error! ${error.message}`;

    function handleSubmit(event) {
        event.preventDefault();
        recordInsight({variables: {userId: user, summary: summary, generationId: metadata?.id}});
    }

    function handleGetMeStarted(event) {
        event.preventDefault();
        generateInsight({
            variables: {userId: user},
            onCompleted: d => {
                setSummary(d.generateTeamInsightForUser.insight.summary);
                setMetadata(d.generateTeamInsightForUser.metadata);
            },
            onError: e => alert("Unable to generate insights: " + e.message)
        });
    }

    return <>
        <Input className="w-full h-48" multiline onChange={setSummary} value={summary}/>
        <div className="text-right">
            <button className={cc("mr-1", buttonStyle().className)} onClick={handleGetMeStarted}>Get me started</button>
            <button className={buttonStyle().className} onClick={handleSubmit}>
                {metadata?.id && <PoweredByAI className="text-sm mr-2" metadata={metadata}/>}
                Save
            </button>
        </div>
    </>;
}

function RecordRecommendations({user}) {
    const [generateRecommendations] = useLazyQuery(GENERATE_RECOMMENDATIONS);
    const [recommendationsList, setRecommendationsList] = useState([{name: '', summary: ''}]);
    const [metadata, setMetadata] = useState(null);

    function addRecommendations(generatedRecommendations) {
        setRecommendationsList([
            ...recommendationsList,
            ...generatedRecommendations.recommendations.map(r => ({
                ...r,
                metadata: generatedRecommendations.metadata
            }))
        ]);
        setMetadata(generatedRecommendations.metadata);
    }

    function handleGetMeStarted(event) {
        event.preventDefault();
        generateRecommendations({
            variables: {userId: user},
            onCompleted: d => {
                console.log("handle 'get me started' with recommendations", d);
                addRecommendations(d.generateRecommendationsForUser);
            },
            onError: e => alert("Unable to generate recommendations: " + e.message)
        });
    }

    return <>
        {recommendationsList && recommendationsList.map(r => <RecordRecommendation user={user} key={r.name} recommendation={r} metadata={r.metadata}/>)}

        <div className="text-right">
            <button className={cc("mr-1", buttonStyle().className)} onClick={handleGetMeStarted}>Get me started</button>
        </div>
    </>;
}

function RecordRecommendation({user, recommendation, metadata}) {
    const [name, setName] = useState(recommendation.name);
    const [summary, setSummary] = useState(recommendation.summary);
    const [recordRecommendation, {data, loading, error}] = useMutation(RECORD_RECOMMENDATION);

    function handleSubmit(event) {
        event.preventDefault();
        recordRecommendation({variables: {userId: user, name: name, summary: summary, generationId: metadata?.id}});
    }

    console.log("Recomemndation data", data);
    if (data?.recordRecommendation) return <>
        <p className="font-bold">{data.recordRecommendation.name}</p>
        <RichText>{data.recordRecommendation.summary}</RichText>
    </>;

    return <>
        Name: <Input value={name} onChange={setName} readonly={!loading} />
        <Input className="w-full" multiline value={summary} onChange={setSummary} readonly={!loading} />
        <div className="text-right">
            <Error error={error?.message} className="mx-1"/>
            <button className={buttonStyle().className} onClick={handleSubmit} disabled={loading}>
                <PoweredByAI className="text-sm mr-2" metadata={metadata} />
                <Loading loading={loading} />
                Save
            </button>
        </div>
    </>;
}