import React, {useContext} from "react";
import {AuthContext} from "react-oauth2-code-pkce";
import {Heading, Paragraph} from "../components/Typography";
import {Timestamp} from "../components/Timestamp";
import {gql, useQuery} from "@apollo/client";
import {GraphQlError} from "../components/GraphQLError";
import {User} from "../components/User";
import {debug} from "../services/Debugger";

const QUERY = gql`
    ${User.fragments.user_with_onboarding}
    query User {
        profile {
            ...User_with_Onboarding
        }
    }
`;

export function ProfileRoute() {
    const {idTokenData} = useContext(AuthContext);

    const {error, data} = useQuery(QUERY);

    debug("Profile route data", data);

    return <div className="max-w-full">
        <Heading>Welcome {idTokenData?.name}</Heading>
        <Paragraph>Logged in using {idTokenData["auth-iss"]} on <Timestamp ts={idTokenData.iat * 1000} /></Paragraph>

        {error && <GraphQlError error={error} />}
        {data && <div className="flex"><User.card user={data.profile} className="p-4 rounded-md bg-gray-100"/></div>}
    </div>;
}
