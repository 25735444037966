import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonDigging} from "@fortawesome/pro-regular-svg-icons";
import {faDisplayCode} from "@fortawesome/pro-regular-svg-icons";
import React, {useEffect} from "react";
import {useIsAuthenticated} from "./components/Authorization.helper";
import {cc} from "./components/Typography";
import {Outlet, useLocation} from "react-router-dom";
import ReactGA from "react-ga";
import {Footer} from "./components/Footer";
import {HeaderWhenAuthenticated, HeaderWhenNotAuthenticated} from "./components/Header";

export function App({children}) {
    // Setting up Google Analytics: https://medium.com/@stheodorejohn/integrating-google-analytics-with-react-router-3e07387d0a45
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return <div className="min-h-screen flex flex-col">
        <EnvironmentBanner environment={process.env.NODE_ENV}/>
        <Banner><FontAwesomeIcon icon={faPersonDigging}/> The only true wisdom is knowing the app is still under construction <FontAwesomeIcon icon={faPersonDigging} flip="horizontal"/></Banner>

        <div className={cc("flex-grow flex flex-col w-full px-4 py-2", !isAuthenticated && "max-w-screen-lg mx-auto")}>
            {isAuthenticated ? <HeaderWhenAuthenticated /> : <HeaderWhenNotAuthenticated />}
            <div className="flex-grow">
                <Outlet />
                {children}
            </div>
            <Footer />
        </div>
    </div>;
}

function EnvironmentBanner({environment}) {
    if (environment === "development") return <Banner bgClassName="bg-blue-600"><FontAwesomeIcon icon={faDisplayCode}/> This is a development environment</Banner>;
}

function Banner({bgClassName = "bg-black", children}) {
    return <div className={cc(bgClassName, "text-white p-2 text-center italic")}>{children}</div>;
}
