import React from "react";
import {gql} from "@apollo/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeCircleCheck, faRightToBracket, faUserCrown} from "@fortawesome/pro-regular-svg-icons";
import {Timestamp} from "./Timestamp";
import {Tag} from "./Tag";
import {faBadgeCheck} from "@fortawesome/pro-solid-svg-icons";

export const User = {
    card: Card,
    profile: Profile,
    fragments: {}
}

User.fragments.onboarding = gql`
    fragment Onboarding on UserOnboarding {
        completedAt
        followedUpAt
    }
`;

User.fragments.user = gql`
    fragment User on User {
        id
        superuser
        createdAt
    }
`;

User.fragments.user_with_onboarding = gql`
    ${User.fragments.user}
    ${User.fragments.onboarding}
    fragment User_with_Onboarding on User {
        ...User
        onboarding {
            ...Onboarding
        }
    }
`;

function Card({user, ...remainingProps}) {
    return <div {...remainingProps}>
        <div className="flex">
            <span className="flex-grow text-lg font-bold">{user.id}</span>{user.superuser &&
            <FontAwesomeIcon className="flex-0" icon={faUserCrown} tooltip="Superuser" />}
        </div>
        <div>
            <FontAwesomeIcon icon={faRightToBracket} className="mr-1" /> Joined on <Timestamp date={user.createdAt}/>
        </div>
        {user.onboarding?.completedAt && <div><FontAwesomeIcon icon={faBadgeCheck} className="mr-1" /> Onboarding completed on <Timestamp date={user.onboarding?.completedAt}/></div>}
        {user.onboarding?.followedUpAt && <div><FontAwesomeIcon icon={faEnvelopeCircleCheck} className="mr-1" />Followed up on <Timestamp date={user.onboarding?.followedUpAt}/></div>}
    </div>
}

function Profile({user, ...remainingProps}) {
    return <div {...remainingProps}>
        Email: {user.id} <br/>
        {user.onboarding?.completedAt && <Tag>onboarded</Tag>}
        {user.onboarding?.followedUpAt && <Tag>followed up</Tag>}
    </div>
}

