import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrochipAi} from "@fortawesome/pro-solid-svg-icons";
import {Tooltip} from "./Tooltip";
import {cc} from "./Typography";

export function PoweredByAI({className, text = logo => logo, metadata}) {
    if (metadata) return <span className={cc("has-tooltip", className)}>{text(<FontAwesomeIcon icon={faMicrochipAi} />)}
        {metadata && <Tooltip>
            Model: {metadata.model}, #{metadata.generationCount} generations<br/>
            Tokens: {metadata.promptTokenCount} prompt/{metadata.generationTokenCount} generation
        </Tooltip>}
    </span>;
}