import React from 'react';
import {Heading, List, Paragraph, Subheading} from "../components/Typography";
import {Timestamp} from "../components/Timestamp";
import {Link} from "react-router-dom";

export function PrivacyPolicyPage({className}) {
    return <div className={className}>
        <Heading><Link to="/">Socrates Advice</Link> Privacy Policy</Heading>

        <Section className="font-bold">
        Last updated: <Timestamp date={Date.parse("17 Oct 2024")}/>
        </Section>

        <Section>
        At Socrates Advice, we take your privacy seriously. This privacy policy explains how we collect, use, and protect your personal information when you use our web application. Please read this policy carefully to understand how we handle your data.
        </Section>

        <Section name="Information We Collect">
            When you use Socrates Advice, we may collect the following types of information:
            <List>
                <Topic name="Personal Information">This includes your name, email address, or other information you provide when signing up for an account or contacting us.</Topic>
                <Topic name="Usage Information">We collect data on how you interact with our application, such as your IP address, browser type, pages visited, and time spent on the site.</Topic>
                <Topic name="Cookies">We may use cookies to enhance your experience. Cookies are small files stored on your device that help us remember your preferences and improve site functionality.</Topic>
            </List>
        </Section>


        <Section name="How We Use Your Information">
            We use the information we collect for the following purposes:
            <List>
                <Topic name="To Provide Our Services">We use your personal information to create your account, give advice, and respond to your inquiries.</Topic>
                <Topic name="Improve User Experience">Your usage data helps us understand how users interact with Socrates Advice, allowing us to improve the app's design and functionality.</Topic>
                <Topic name="Communication">We may send you emails or notifications to keep you informed about updates, new features, or important information related to your account.</Topic>
                <Topic name="Security">We may use your information to monitor for suspicious activities or unauthorized access to your account.</Topic>
            </List>
        </Section>

        <Section name="How We Share Your Information">
            We value your privacy and will not sell or share your personal information with third parties, except in the following situations:
            <List>
                <Topic name="Service Providers">We may share information with trusted service providers who help us run our application, such as hosting services or email delivery providers.</Topic>
                <Topic name="Legal Requirements">We may disclose your information if required to do so by law or to protect our rights and the safety of others.</Topic>
            </List>
        </Section>

        <Section name="How We Protect Your Information">
            We take reasonable steps to protect your personal information from unauthorized access, loss, or misuse. This includes using encryption, secure servers, and other safeguards. However, please note that no method of transmission over the internet is completely secure.
        </Section>

        <Section name="Your Choices and Rights">
            You have the right to:
            <List>
                <Topic name="Access and Update">View and update the personal information you provide us.</Topic>
                <Topic name="Delete Your Account">Request the deletion of your account and personal data.</Topic>
                <Topic name="Opt-Out">Unsubscribe from our email communications by following the instructions in the email.</Topic>
            </List>
        </Section>

        <Section name="Children's Privacy">
            Socrates Advice is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it.
        </Section>

        <Section name="Changes to This Policy">
            We may update this privacy policy from time to time. Any changes will be posted on this page with the updated date. We encourage you to review this policy regularly.
        </Section>

        <Section name="Contact Us">
            <span>If you have any questions or concerns about this privacy policy or how we handle your personal information, please contact us at <Link to="mailto:privacy@socratesadvice.com">privacy@socratesadvice.com</Link>.</span>
            By using Socrates Advice, you agree to the terms outlined in this privacy policy.
        </Section>
    </div>
}

function Section({name, counter, className, children}) {
    return <>
        {name && <Subheading>{counter && `${counter}. `}{name}</Subheading>}
        {React.Children.map(children, c => <Paragraph className={className}>{c}</Paragraph>)}
    </>
}

function Topic({name, children}) {
    return <li>
        <span className="font-bold">{name}</span>: {children}
    </li>;
}
