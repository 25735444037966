import React from "react";
import {List, Paragraph, Subheading} from "./Typography";
import {gql, useQuery} from "@apollo/client";
import {Center} from "./Center";
import {GraphQlError} from "./GraphQLError";
import {Timestamp} from "./Timestamp";
import {RichText} from "./RichText";

export const allTeamInsightFields = gql`
    fragment AllTeamInsightFields on TeamInsight {
        summary
        createdAt
        source
    }
`;

export const allRecommendationFields = gql`
    fragment AllRecommendationFields on Recommendation {
        name
        summary
        createdAt
        source
    }
`;

const QUERY = gql`
    ${allTeamInsightFields}
    ${allRecommendationFields}
    query {
        latestTeamInsight {
            ...AllTeamInsightFields
        }
        recommendationList {
            ...AllRecommendationFields
        }
    }
`;

export function TeamSummaryQuery({className}) {
  const {loading, error, data} = useQuery(QUERY);

  if (loading) return <Center><p>Loading...</p></Center>;

  if (error) return <GraphQlError error={error}/>;

  return <div className={className}>
    <TeamSummary teamInsight={data.latestTeamInsight}/>
    {data?.recommendationList && <Recommendations recommendations={data.recommendationList}/>}
  </div>;
}

export function TeamSummary({teamInsight, className}) {
  if (!teamInsight)
    return <Paragraph className={className}>Socrates needs more observations to provide insights.</Paragraph>;

  return <div className={className}>
    <Subheading>Your team in a nutshell</Subheading>
    <RichText>{teamInsight.summary}</RichText>
    <Paragraph className="text-right">
      <Timestamp ts={teamInsight.createdAt} />
    </Paragraph>
  </div>;
}

function Recommendations({recommendations, className}) {
    return <div className={className}>
        <Subheading>Some ideas to improve</Subheading>
        <List>
            {recommendations.map(r => <Recommendation recommendation={r} />)}
        </List>
    </div>;
}

function Recommendation({recommendation}) {
    return <div>
        <span className="font-bold">{recommendation.name}</span>
        <RichText>{recommendation.summary}</RichText>
    </div>
}
