import React, {Children} from "react";

export function Container({
      className,
      innerClassName,
      wrapper = (child, index, innerClassName) => <div className={innerClassName} key={index}>{child}</div>,
      children
    }) {
  return <div className={className}>
    {Children.map(children, (child, index) => wrapper(child, index, innerClassName))}
  </div>
}
