import React, {useContext} from "react";
import {AuthContext} from "react-oauth2-code-pkce";
import {MainMenu, SubMenu} from "./MainMenu";
import {NavLink} from "react-router-dom";
import {LoginButton, LogoutButton} from "./Authentication";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import {ScreenWide} from "./styles/ScreenWide";

export function HeaderWhenNotAuthenticated() {
  return <nav className="flex flex-row-reverse justify-between bg-white">
    <LoginButton bgClass="bg-soc-cheeks-dark hover:bg-soc-cheeks"><FontAwesomeIcon icon={faGoogle}/> &nbsp; Sign in</LoginButton>
  </nav>;
}

export function HeaderWhenAuthenticated() {
  const {idTokenData} = useContext(AuthContext);

  return <ScreenWide preservePadding className="-mt-2 sticky top-0 shadow-md bg-white z-40 mb-2">
    <MainMenu className="max-w-screen-2xl mx-auto pt-2">
      <NavLink to="/assessments" aria-current="page">Assessments</NavLink>
      <NavLink to="/polls" aria-current="page">Polls</NavLink>
      <SubMenu name="Tools" aria-current="page">
        <NavLink to="/tools/improvement-calculator" aria-current="page">Improvement calculator</NavLink>
      </SubMenu>
      <NavLink to="/admin" aria-current="page" role="admin">Admin</NavLink>
      <SubMenu image={idTokenData?.picture} name={idTokenData?.name}>
        <NavLink to="/profile" aria-current="page">Profile</NavLink>
        <LogoutButton>Logout</LogoutButton>
      </SubMenu>
    </MainMenu>
  </ScreenWide>
}
