import {cc} from "./Typography";

export function Timestamp({ts, date}) {
    const timeFormat = {
        hour: 'numeric',
        minute: 'numeric',
    };

    const dateFormat = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    };

    if (ts) return new Date(ts).toLocaleString(undefined, {...timeFormat, ...dateFormat});
    return new Date(date).toLocaleDateString(undefined, dateFormat);
}

Timestamp.tag = function Tag({ts, date, className}) {
    return <div className={cc(className, "text-right text-xs italic")}><Timestamp ts={ts} date={date} /></div>;
}