import React from "react";
import Markdown from "react-markdown";
import {List, Paragraph} from "./Typography";

export function RichText({children}) {
    return <Markdown components={{
        p(props) {
            const {node, ...rest} = props;
            return <Paragraph {...rest} />;
        },
        ol(props) {
            const {node, ...rest} = props;
            return <List ordered {...rest} />;
        },
        ul(props) {
            const {node, ...rest} = props;
            return <List {...rest} />;
        }
    }}>{children}</Markdown>
}