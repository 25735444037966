import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/pro-regular-svg-icons";
import {Link} from "react-router-dom";

export function Footer() {
  return <footer className="flex-none flex flex-col md:flex-row justify-between gap-x-2 text-sm text-center mt-6">
            <span className="md:text-left">
                &copy; MMXXV Socrates Advice Inc.
            </span>
    <span>
                version {process.env.REACT_APP_VERSION}
            </span>
    <Link to={"/privacy-policy"}>Privacy Policy</Link>
    <Feedback className="lg:text-right"/>
  </footer>
}

function Feedback(className) {
  return <Link to="mailto:feedback@socratesadvice.com?subject=SocratesAdvice%20Feedback" className={className}>
    <FontAwesomeIcon icon={faEnvelope}/> Give us feedback
  </Link>;
}
