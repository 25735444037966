import {cc} from "../components/Typography";

export function buttonStyle({text, bg} = {}) {
    return {
        textClassName: text || "text-white",
        bgClassName: bg || "bg-soc-grey enabled:hover:bg-soc-border disabled:opacity-60",

        default: "font-medium rounded-md py-2 px-4 text-center inline-block",

        get className() {
            return [this.textClassName, this.bgClassName, this.default].join(" ");
        }
    }
}