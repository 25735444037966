import React from "react";
import {cc} from "./Typography";

export function Quote({className, author, children}) {
  return <blockquote className={cc("p-3", className)}>
    <span className="absolute -m-3 text-6xl text-soc-cheeks">&#8220;</span>
    <div className="relative text-lg italic z-1">{children}</div>
    <div className="text-right text-sm italic">&mdash; {author}</div>
  </blockquote>
}
