import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faTriangleExclamation} from "@fortawesome/pro-solid-svg-icons";
import {Tooltip} from "./Tooltip";
import {cc} from "./Typography";

export function Error({className, error}) {
    if (error) return <span className={cc(className, "has-tooltip")}>
        <FontAwesomeIcon icon={faTriangleExclamation} className="text-red-600"/>
        <Tooltip>{error}</Tooltip>
    </span>;
}

export function Loading({className, loading}) {
    if (loading) return <FontAwesomeIcon className={className} icon={faCircleNotch} spin />;
}